import React, { useEffect, useState } from "react";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";
// import { db } from '../../firebase';
// import PropTypes from 'prop-types';

function TimerRemote() {
  const {
    seconds,
    minutes,
    halfLength,
    setSeconds,
    setMinutes,
    setTime,
    setTimeIsRed,
    timeForward,
    started,
    setStarted,
    updateTimerState,
  } = useScoreboardContext();

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    let intervalId = null;
    let intervalId1 = null;
    var multiplier = timeForward ? 1 : -1;
    var start =
      Date.now() - multiplier * minutes * 60000 - multiplier * seconds * 1000;

    if (firstLoad) {
      intervalId1 = setInterval(() => {
        setFirstLoad(false);
      }, 1000);
    } else {
      console.log({ start, started, timeForward, minutes, seconds });
      updateTimerState(start);
    }

    if (started) {
      intervalId = setInterval(() => {
        var delta = Date.now() - start;
        var seconds = Math.floor(delta / 1000);
        seconds = seconds % 60;
        var minutes = Math.floor(delta / 60000);

        if (timeForward) {
          setMinutes(minutes);
          setSeconds(seconds);

          if (seconds === 0) {
            if (minutes === halfLength || minutes == halfLength * 2)
              setTimeIsRed(true);
          }
        } else {
          delta = 1000 + start - Date.now();
          minutes = Math.floor(delta / 60000);
          seconds = Math.floor(delta / 1000) - minutes * 60;

          setSeconds(seconds);
          setMinutes(minutes);

          if (seconds === 0) {
            if (minutes === 0 || minutes == halfLength) {
              setStarted(false);
              setTimeIsRed(true);
            }
          }
        }

        // Check if the timer has reached its end
        if (minutes === 0 && seconds === 0) {
          setStarted(false);
          setTimeIsRed(true);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
      if (typeof intervalId1 === "function") clearInterval(intervalId1);
    };
  }, [started]);

  useEffect(() => {
    setTime(
      (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds)
    );
  }, [seconds, minutes]);

  return <></>;
}

export default TimerRemote;
