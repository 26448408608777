/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FeedbackModal from "../../../components/Modal/ModalSettings/FeedbackModal";
import TennisSettingsModal from "../../../components/Modal/ModalSettings/TennisSettingsModal";
import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import { useTennisContext } from "./TennisContext";
import { HiSwitchHorizontal } from "react-icons/hi";
import AllScoreControls from "./AllScoreControls";
import PropTypes from "prop-types";
import InputButton from "../InputButton";
import { sportsSettings } from "../Initialise_Settings";
import useTranslations from "../../../contexts/useTranslations";

const TennisController = ({ isController }) => {
  const t = useTranslations();

  const {
    teamA,
    teamB,
    setTeamA,
    setTeamB,
    currentSport,
    tennisVariant,
    currentScoreboard,
    setCurrentScoreboard,
  } = useScoreboardContext();

  const {
    addScore,
    currentScore,
    setCurrentScore,
    legs,
    sets,
    setLegs,
    setSets,
    undo,
    setIsFirstServer,
    setIsInitialGameServe,
    notice,
    setNotice,
    showNotice,
    setShowNotice,
    showModalFeedback,
    setShowModalFeedback,
    showModalSettings,
    setShowSettingsModal,
    isManualScores,
    setIsManualScores,
    updateFB,
    scoreIsTiebreaker,
    setScoreIsTiebreaker,
    setGameNumber,
    setUndoHistory,
    finalLegIsTiebreaker,
  } = useTennisContext();

  const [buttonColor, setButtonColor] = useState(["primary", "primary"]);

  useEffect(() => {
    setTeamA(t("noun:player") + " A");
    setTeamB(t("noun:player") + " B");
  }, []);

  useEffect(() => {
    let _currentScore = [0, 0];
    setCurrentScoreboard(0);

    if (tennisVariant === "2") {
      _currentScore = [{ player1: 0, player2: 0 }];
      setCurrentScore(_currentScore);
      setGameNumber(0);
      setIsManualScores(false);
    } else {
      _currentScore = [0, 0];
      setCurrentScore(_currentScore);
      setGameNumber(0);
      setLegs([0, 0]);
      setSets([0, 0]);
      setIsManualScores(false);
    }

    setUndoHistory([
      {
        currentScore: _currentScore,
        legs: [0, 0],
        sets: [0, 0],
        isFirstServer: false,
        finalLegIsTiebreaker,
        scoreIsTiebreaker,
        gameNumber: 0,
        gameIsOver: false,
      },
    ]);

    updateFB({ tennisVariant: tennisVariant, currentScore: _currentScore });
  }, [tennisVariant]);

  const changeNotice = (notice, num) => {
    if (notice === "") {
      setShowNotice(false);
      setButtonColor(["primary", "primary"]);

      // Update Firebase when notice is cleared
      updateFB({
        showNotice: false,
        notice: "",
      });
      return;
    }

    let otherNum = num === 1 ? 0 : 1;
    setNotice(notice);
    let notShowNotice = !showNotice;

    if (notShowNotice) {
      setButtonColor((color) => {
        let temp = [...color];
        temp[num] = "success";
        temp[otherNum] = "primary";
        return temp;
      });
    } else {
      setButtonColor(["primary", "primary"]);
    }

    setShowNotice(notShowNotice);

    // Update Firebase with the new notice and showNotice state
    updateFB({
      showNotice: notShowNotice,
      notice: notice,
    });
  };

  const leftNoticeText = useMemo(() => {
    return tennisVariant === "2" ? t("noun:game-point") : t("noun:tiebreak");
  }, [tennisVariant]);

  return (
    <Container
      style={{
        maxWidth: 660,
        padding: 0,
        marginLeft: 0,
        height: "300px",
        marginTop: 5,
        marginBottom: isController ? 40 : 0,
      }}
    >
      <Row className="justify-content-md-center mg-auto">
        {isController ? (
          <Button
            className="m-auto"
            variant="success"
            style={{ width: "80%" }}
            onClick={() => setIsManualScores(!isManualScores)}
          >
            {isManualScores ? "Automatic" : "Manual Score"}
          </Button>
        ) : null}
        {!isManualScores ? (
          <>
            {" "}
            <Col xs={isController ? 12 : 9} style={{ height: 200 }}>
              {/* Top Row */}
              <Row className="mt-1">
                <Col>
                  <Button
                    variant={buttonColor[0]}
                    className="w-100"
                    onClick={() => changeNotice(leftNoticeText, 0)}
                  >
                    {leftNoticeText}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={buttonColor[1]}
                    className="w-100 "
                    onClick={() => changeNotice(t("noun:match-point"), 1)}
                  >
                    {t("noun:match-point")}
                  </Button>
                </Col>
              </Row>
              {/* Second Row */}
              <Row className="mt-1">
                <Col>
                  <Button
                    variant="dark"
                    className="w-100"
                    onClick={() => undo()}
                  >
                    {t("noun:undo")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="info"
                    className="w-100 "
                    onClick={() =>
                      setIsFirstServer((server) => {
                        let notServer = !server;
                        updateFB({ isFirstServer: notServer });
                        setIsInitialGameServe(notServer);
                        return notServer;
                      })
                    }
                  >
                    {t("noun:switch-server")}
                  </Button>
                </Col>
              </Row>
              {/* Third Row */}
              <Row className="mt-1">
                <Col>
                  <Button
                    variant="warning"
                    className="w-100 height-100"
                    onClick={() => addScore(0)}
                  >
                    {teamA}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    className="w-100 height-100"
                    onClick={() => addScore(1)}
                  >
                    {teamB}
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col
            xs={isController ? 12 : 9}
            className="mt-1"
            style={{ height: 200, marginRight: 5 }}
          >
            <Row>
              <Col>
                <Button
                  variant={buttonColor[0]}
                  className="w-100 mb-1"
                  onClick={() => {
                    changeNotice(t("noun:match-point"), 0);
                  }}
                >
                  {t("noun:match-point")}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  className="w-100 mb-1"
                  onClick={() =>
                    setIsFirstServer((server) => {
                      let notServer = !server;
                      updateFB({ isFirstServer: notServer });
                      return notServer;
                    })
                  }
                >
                  <HiSwitchHorizontal />
                </Button>
              </Col>
              <Col>
                <Button
                  variant={buttonColor[1]}
                  className="w-100 mb-1"
                  onClick={() => {
                    changeNotice("Break Point", 1);
                  }}
                >
                  {t("noun:breakpoint")}
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="ml-0 pl-0 pr-1">
                <AllScoreControls
                  currentPlayer={0}
                  currentScore={currentScore}
                  setCurrentScore={setCurrentScore}
                  setCurrentGames={setLegs}
                  setCurrentSets={setSets}
                  setShowNotice={() => changeNotice("", 0)}
                  sets={sets[0]}
                  games={legs[0]}
                  scoreIsTiebreaker={scoreIsTiebreaker}
                />
              </Col>
              <Col>
                <AllScoreControls
                  currentPlayer={1}
                  currentScore={currentScore}
                  setCurrentScore={setCurrentScore}
                  setCurrentGames={setLegs}
                  setCurrentSets={setSets}
                  setShowNotice={() => changeNotice("", 1)}
                  sets={sets[1]}
                  games={legs[1]}
                  scoreIsTiebreaker={scoreIsTiebreaker}
                />
              </Col>
            </Row>
          </Col>
        )}
        {!isController ? (
          <Col>
            <Row>
              <Button
                id="control-settings-btn"
                variant="success"
                className="w-100 mt-1"
                onClick={() => setShowSettingsModal((show) => !show)}
              >
                {t("noun:settings")}
              </Button>
            </Row>
            <Row>
              <Button
                variant="warning"
                className="w-100 mt-1"
                onClick={() => setShowModalFeedback((show) => !show)}
              >
                {t("noun:feedback")}
              </Button>
            </Row>
            {isManualScores ? (
              <Row>
                <Button
                  variant={scoreIsTiebreaker ? "warning" : "success"}
                  className="w-100 mt-1"
                  onClick={() => {
                    setCurrentScore([0, 0]);
                    setScoreIsTiebreaker((isTiebreak) => !isTiebreak);
                  }}
                >
                  {t("noun:is-tiebreak-game")}
                </Button>
              </Row>
            ) : null}
          </Col>
        ) : null}
      </Row>
      <Row>
        {isController && !isManualScores ? (
          <p className={"text-center"}>
            {t("tennis-controller:use-desktop-version")}
          </p>
        ) : null}
      </Row>
      {showModalSettings ? <TennisSettingsModal /> : null}
      {showModalFeedback ? <FeedbackModal /> : null}
      {sportsSettings?.[
        tennisVariant === "2" && currentSport == "t"
          ? "pb"
          : tennisVariant === "1" && currentSport == "t"
          ? "pp"
          : currentSport
      ]?.scoreboards?.[currentScoreboard]?.hasCrests ? (
        <CrestButtons />
      ) : null}
    </Container>
  );
};

const CrestButtons = () => {
  const t = useTranslations();

  return (
    <div>
      <InputButton
        text={t("noun:flag") + " 1"}
        top={100}
        left={530}
        refer="crest1"
      />

      <InputButton
        text={t("noun:flag") + " 2"}
        top={135}
        left={530}
        refer="crest2"
      />
    </div>
  );
};

TennisController.propTypes = {
  isController: PropTypes.bool,
};

export default TennisController;
