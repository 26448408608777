import React, { useEffect, useState } from "react";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";

const timestamp = {
  1: true,
  2: true,
  3: true,
  7: true,
  18: true,
  22: true,
  27: true,
  29: true,
  32: true,
  33: true,
  34: true,
  38: true,
  44: true,
  50: true,
  55: true,
  58: true,
  68: true,
  75: true,
  85: true,
  89: true,
  90: true,
};

function Timer() {
  const {
    seconds,
    minutes,
    halfLength,
    setSeconds,
    setMinutes,
    setTime,
    setTimeIsRed,
    timeForward,
    started,
    setStarted,
    setFirstHalfCounter,
    setSecondHalfCounter,
    updateTimerState,
  } = useScoreboardContext();

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    let intervalId = null;
    let intervalId1 = null;
    var multiplier = timeForward ? 1 : -1;
    const now = Date.now();
    var start =
      now - multiplier * minutes * 60000 - multiplier * seconds * 1000;

    if (firstLoad) {
      intervalId1 = setInterval(() => {
        setFirstLoad(false);
      }, 3000);
    } else {
      updateTimerState(start);
    }

    if (started) {
      intervalId = setInterval(() => {
        var delta = Date.now() - start;
        var _seconds = Math.floor(delta / 1000);
        _seconds = _seconds % 60;
        var _minutes = Math.floor(delta / 60000);

        if (timeForward) {
          setMinutes(_minutes);
          setSeconds(_seconds);

          if (_seconds === 0) {
            if (_minutes === halfLength || _minutes == halfLength * 2) {
              setTimeIsRed(true);
            }

            if (timestamp?.[_minutes] && _minutes <= halfLength) {
              setFirstHalfCounter((count) => count + 1);
            }

            if (timestamp?.[_minutes] && _minutes >= halfLength) {
              setSecondHalfCounter((count) => count + 1);
            }
          }
        } else {
          delta = 1000 + start - Date.now();
          const remainingMinutes = Math.floor((delta / 1000 / 60) % 60);
          let remainingSeconds = Math.floor((delta / 1000) % 60);

          if (minutes <= 0 && seconds <= 0) {
            setStarted(false);
          }

          if (remainingSeconds < 0) remainingSeconds += 60; // Handle countdown edge case

          setSeconds(remainingSeconds);
          setMinutes(remainingMinutes);

          if (remainingSeconds === 0) {
            if (remainingMinutes === 0 || remainingMinutes === halfLength) {
              setStarted(false);
              setTimeIsRed(true);
            }

            if (minutes <= 0 && seconds <= 0) {
              setStarted(false);
              setMinutes(0);
              setSeconds(0);
              setTimeIsRed(true);
              return;
            }

            if (
              timestamp?.[remainingMinutes] &&
              remainingMinutes >= halfLength
            ) {
              setFirstHalfCounter((count) => count + 1);
            }

            if (
              timestamp?.[remainingMinutes] &&
              remainingMinutes < halfLength
            ) {
              setSecondHalfCounter((count) => count + 1);
            }
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
      if (typeof intervalId1 === "function") clearInterval(intervalId1);
    };
  }, [started]);

  useEffect(() => {
    setTime(
      (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds)
    );
  }, [seconds, minutes]);

  return <></>;
}

export default Timer;
